import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FactorList = _resolveComponent("FactorList")!
  const _component_Factor = _resolveComponent("Factor")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.diagnostic && _ctx.selectedFactorId < 0)
      ? (_openBlock(), _createBlock(_component_FactorList, {
          key: 0,
          factors: _ctx.factors,
          diagnostic: _ctx.diagnostic,
          onSetActiveFactor: _ctx.setActiveFactor,
          onCancelFactorResult: _ctx.cancelFactorResult
        }, null, 8, ["factors", "diagnostic", "onSetActiveFactor", "onCancelFactorResult"]))
      : _createCommentVNode("", true),
    (_ctx.diagnostic && _ctx.selectedFactorId >= 0)
      ? (_openBlock(), _createBlock(_component_Factor, {
          key: 1,
          factor: _ctx.factors.find((factor) => factor.id === _ctx.selectedFactorId),
          result: 
      _ctx.diagnostic.results.filter(
        (result) => result.level_details.factor === _ctx.selectedFactorId
      )[0]
    ,
          diagnosticId: _ctx.diagnostic.id,
          editable: !_ctx.diagnostic.finished,
          onCancelFactor: _ctx.cancelFactorResult,
          onNewResult: _ctx.updateResults
        }, null, 8, ["factor", "result", "diagnosticId", "editable", "onCancelFactor", "onNewResult"]))
      : _createCommentVNode("", true)
  ], 64))
}
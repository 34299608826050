
import { defineComponent, onMounted, ref, computed, PropType } from "vue";
import CardTitleJaya from "@/components/global/CardTitleJaya.vue";
import { deepCopy, openLinkAgriBest } from "@/core/helpers/globalJaya";
import VideoComponent from "@/components/global/VideoComponent.vue";
import { Factor } from "@/store/modules/DiagnosticsModule";

export default defineComponent({
  name: "facteur",
  methods: { openLinkAgriBest },
  emits: ["cancelFactor", "newResult"],
  props: {
    factor: { type: Object as PropType<Factor>, required: true },
    editable: { type: Boolean, default: false },
    result: { type: Object },
    diagnosticId: { type: String, default: "" },
  },
  components: {
    VideoComponent,
    CardTitleJaya,
  },
  setup(props, { emit }) {
    let factorLevelChoice = ref<number>(-1);
    let pauseVideo = ref<boolean>(false);
    const setPause = (value) => {
      pauseVideo.value = value;
    };
    onMounted(() => {
      if (props.result) {
        factorLevelChoice.value = props.result.level;
      }
      let myModalEl = document.getElementById("video");
      myModalEl?.addEventListener("hidden.bs.modal", function () {
        setPause(true);
      });
    });

    const sortedFactorLevels = computed(() => {
      let factorLevelsCopy = deepCopy(props.factor.levels);
      return factorLevelsCopy.sort((a, b) => (a.level < b.level ? -1 : 1));
    });

    const getStyle = computed(() => {
      if (window.innerWidth < 576) {
        return {
          backgroundImage:
            "url(" +
            require(`@/../public/media/images/illustrated/${props.factor.number}.svg`) +
            ")",
          backgroundPosition: "100% -5px",
          backgroundRepeat: "no-repeat",
          backgroundSize: "auto 75px",
          padding: "4rem 2.25rem !important",
        };
      } else if (window.innerWidth < 768) {
        return {
          backgroundImage:
            "url(" +
            require(`@/../public/media/images/illustrated/${props.factor.number}.svg`) +
            ")",
          backgroundPosition: "100% -10px",
          backgroundRepeat: "no-repeat",
          backgroundSize: "auto 100px",
        };
      } else {
        return {
          backgroundImage:
            "url(" +
            require(`@/../public/media/images/illustrated/${props.factor.number}.svg`) +
            ")",
          backgroundPosition: "90% -10px",
          backgroundRepeat: "no-repeat",
          backgroundSize: "auto 100px",
        };
      }
    });

    const openAgribestLinkFactor = () => {
      openLinkAgriBest(
        `${process.env.VUE_APP_ILLUSTRATED_URL}scrapbook/${props.diagnosticId}`
      );
    };

    const validateResult = () => {
      if (factorLevelChoice.value >= 0) {
        emit("newResult", {
          resultLevel: factorLevelChoice.value,
        });
      }
    };
    const cancelResult = () => {
      emit("cancelFactor");
    };

    return {
      factorLevelChoice,
      sortedFactorLevels,
      validateResult,
      cancelResult,
      setPause,
      getStyle,
      pauseVideo,
      openAgribestLinkFactor,
    };
  },
});
